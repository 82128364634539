<template>
  <div
    class="selected-monitorings-container"
    :class="{
      'selected-monitorings-container__open': open
    }"
  >
    <div
      class="selected-monitorings-trigger"
      @click="switchItemsState()"
    >
      <div class="selected-monitorins-info">
        <div class="mr-1 selected-monitorings-dropdown-icon">
          <b-icon icon="chevron-compact-down" />
        </div>
        <div class="mr-1 selected-monitorings-count">
          {{ monitorings.length }}
        </div>
        <div class="mr-1 selected-monitorings-title">
          {{ monitoringsLabel }}
        </div>
      </div>
      <div class="selected-monitorings-doctor">
        Врач:
        {{ monitorings.length ? monitorings[0].doctor.fullName : 'Не выбран' }}
      </div>
    </div>
    <div
      v-if="open"
      class="selected-monitorings-items"
    >
      <div
        v-for="item in monitorings"
        :key="item.id"
        class="selected-monitorings-item"
      >
        <span class="selected-moinitorings-item__title">
          {{ item.name }}
        </span>
        <button
          class="selected-monitorings-item__delete"
          @click="uncheckItem(item.id)"
        >
          <b-icon icon="trash" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { declOfNum } from '@/helpers/utils';

export default {
  name: 'MonitoringsOpenableList',
  props: {
    monitorings: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    monitoringsLabel() {
      return declOfNum(this.monitorings.length, [
        'мониторинг',
        'мониторинга',
        'мониторингов',
      ]);
    },
  },
  methods: {
    switchItemsState() {
      this.open = !this.open;
    },
    uncheckItem(id) {
      this.$emit('uncheck-item', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-monitorings-container {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &__open {
    .selected-monitorings-dropdown-icon {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .selected-monitorings-trigger {
    height: 40px;
    border: 1px solid #dee2e6;
    border-radius: 2px;
    padding: 8px 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .selected-monitorins-info {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      .selected-monitorings-dropdown-icon {
        font-size: 12px;

        svg {
          transition: all ease-in-out 0.2s;
        }
      }
    }

    .selected-monitorings-doctor {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .selected-monitorings-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .selected-monitorings-item {
      height: 40px;
      border-left: 1px solid #dee2e6;
      border-right: 1px solid #dee2e6;
      border-radius: 2px;
      padding: 6px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &:last-child {
        border-bottom: 1px solid #dee2e6;
        border-radius: 0 0 2px 2px;
      }

      .selected-moinitorings-item__title {
        font-size: 16px;
        line-height: 24px;
      }

      .selected-monitorings-item__delete {
        cursor: pointer;
        font-size: 14px;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
