<template>
  <b-modal
    id="remove-multy-monitorings"
    v-model="isOpen"
    size="xl"
    centered
    title="Подтверждение удаления мониторингов"
  >
    <template>
      <h6>Выбранные мониторинги</h6>
      <template v-if="localMonitorings.length">
        <MonitoringsOpenableList
          v-for="group in groupedMonitorings"
          :key="group.name"
          :monitorings="group.items"
          @uncheck-item="uncheckItem"
        />
      </template>
      <MonitoringsOpenableList
        v-else
        :monitorings="localMonitorings"
        @uncheck-item="uncheckItem"
      />

      <p class="mt-4 mb-4">
        Вы уверены, что хотите безвозвратно удалить выбранные мониторинги? Это
        действие необратимо.
      </p>
    </template>

    <template #modal-footer>
      <b-button
        type="primary"
        variant="danger"
        @click="confirm"
      >
        Удалить мониторинги
      </b-button>
      <b-button
        type="primary"
        variant="primary"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import MonitoringsOpenableList from '@/components/Patients/MonitoringsOpenableList';

export default {
  name: 'RemoveMultyMonitoringsModal',

  components: {
    MonitoringsOpenableList,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    monitorings: {
      type: Array,
      required: true,
    },
    onClear: {
      type: [Function],
      default: () => {},
    },
    onConfirm: {
      type: [Function],
      default: () => {},
    },
    onUncheck: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      groupedMonitorings: [],
      isOpen: true,
      localMonitorings: this.monitorings,
    };
  },
  watch: {
    localMonitorings: {
      deep: true,
      handler() {
        this.groupMonitorings();
      },
    },
  },

  async mounted() {
    this.groupMonitorings();
  },

  methods: {
    groupMonitorings() {
      const result = [];

      this.localMonitorings.forEach((item) => {
        const existingItem = result.find(
          (i) => i.name === item.doctor.fullName,
        );

        if (existingItem) {
          existingItem.items.push(item);
        } else {
          result.push({
            name: item.doctor.fullName,
            items: [item],
          });
        }
      });

      this.groupedMonitorings = result;
    },

    onClose() {
      this.$emit('input', false);
    },
    clearForm() {
      this.$emit('onClear');
    },

    confirm() {
      this.$emit('onConfirm');
      this.onClose();
    },

    uncheckItem(id) {
      if (id) {
        this.localMonitorings = this.localMonitorings.filter((m) => m.id !== id);
      }
      this.$emit('onUncheck', id);
    },
  },
};
</script>

<style lang="scss">
#remove-multy-monitorings {
  h5,
  h6 {
    font-weight: 500;
  }
}
</style>
